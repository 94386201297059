/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./productViewStyle";
import React from "react";
import env from "../../env";
import { SiteLink, NotFound } from "../../components";
import { useGet } from "../../components";
import { useRouter } from "../../components";
import utils from "../../utils";
import { ErrorPopup, Loading } from "../../components";
import { Breadcrumb } from "../../components/breadcrumb/breadcrumb";
import { ViewPartial } from "../../cms/pages/partials/viewPartial";
//import { CommonProductBottom } from '../../cms/pages/partials/commonProductBottom';
//import { Tile } from './productTile';
import { YoutubeEmbed } from "../../components";
import { useInstagram } from "../../components";
import { useBV } from "../bazzarVoice/useBV";
//import { ImagePopup } from 'modules/imagePopup/imagePopup';
// import InstagramEmbed from "react-instagram-embed";
import Slider from "react-slick";
import FsLightbox from "fslightbox-react";
import { MetaTag } from "../../components";
import { FaRegFilePdf } from "react-icons/fa";
import { FaRegPlayCircle } from "react-icons/fa";
import { debounce } from "../../lib/utils-core/debounce";
import { gtm } from "../../lib/tracking";
import { ProductTiles } from "../productTile--slider/productTiles";
import { IoMdOpen } from "react-icons/io";
import { InstaEmbedByLink } from "../embed/instagram";
import { TiktokEmbedByLink } from "../embed/tiktok";

export function ProductView(props) {
  const { query } = useRouter();
  const [loaded, setLoaded] = React.useState(false);
  const get = useGet();
  useInstagram();

  const productCode = query.id || "";
  const category = query.category || "";
  const slug = query.slug || "";
  //console.log(category, slug, productCode)

  React.useEffect(() => {
    const url = productCode
      ? `/api/product/getProduct/${productCode}`
      : `/api/product/getProductByUrl?category=${category}&slug=${slug}`;
    get.send(env.apiBase + url);
    setLoaded(false);
    // eslint-disable-next-line
  }, [productCode, slug]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors() && get.containsErrorMessage("no product")) {
    return <NotFound />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  console.log(get);
  const data = get.response;

  if (get.done() && !loaded) {
    setLoaded(true);
    const pageUrl = productCode
      ? `/product/${productCode}`
      : `/products/${category}/${slug}`;
    const pageTitle =
      data.totalCnt === 0 ? "No product found" : data.productName;
    gtm.pageView(pageUrl, pageTitle);
  }

  if (data.totalCnt === 0) return <div>No product found.</div>;
  return (
    <React.Fragment>
      {/* <div className="single-banner banner-item bannerSize--thin"
        style={{ backgroundImage: utils.css.bgUrlStyle(utils.site.resourcePath('/sites/cuisinart/media/banners/recipe-wch-raspcc1crop.jpg')) }}>
      </div> */}
      <div css={style.topPadding}></div>
      <Product product={data} />

      {/* <CommonProductBottom /> */}
      <ViewPartial />
    </React.Fragment>
  );
}

function Product({ product }) {
  const mainPhoto =
    product.productImages.length > 0
      ? product.productImages[0]
      : { imageUrl: "/assets/product-empty.png", alt: product.productName };
  const allPhotos = product.productImages || [];
  //const otherPhotos = product.productImages.length > 0 ? product.productImages.filter(x => !x.isPackage) : [];
  const packagePhotos =
    product.productImages.length > 0
      ? product.productImages.filter((x) => x.isPackage)
      : [];

  const popupImages = allPhotos.map((x) => x.imageUrl);

  const [currentTab, setCurrentTab] = React.useState("wheretobuy");
  const [heroImage, setHeroImage] = React.useState(mainPhoto);

  // const youtubeSection = [];
  // if(product.youtubeSection && product.youtubeSection.length > 0){
  //   product.youtubeSection.forEach(el => {
  //     const video = JSON.parse(el.itemValue);
  //     const videoId = utils.url.getYoutubeId(video.video);
  //     const posterImage = video.posterImage || `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  //     youtubeSection.push({
  //       alt : null,
  //       imageUrl : posterImage,
  //       isPackage : false, type : 'youtube',
  //       video : video.video
  //     });
  //   });
  // }

  const [toggler, setToggler] = React.useState(false);
  //const [popupImages, setPopupImages] = React.useState();
  const [popupIndex, setPopupIndex] = React.useState(0);

  const [isMobile, setIsMobile] = React.useState(utils.site.isMobile());

  React.useEffect(() => {
    const resized = debounce(() => {
      setIsMobile(utils.site.isMobile());
    }, 1000);
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", resized);
    }

    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener("resize", resized);
      }
    };
  }, []);

  React.useLayoutEffect(() => {
    if (!utils.site.isNZ) {
      setTimeout(() => {
        bv.sendEvent(
          "WhereToBuy",
          "WhereToBuyTab_ProductPage",
          product.productCode
        );
      }, 1500);
    }

    // eslint-disable-next-line
  }, []);

  // BV
  const onShowReview = React.useCallback((e) => {
    //e.stopPropagation();
    showTab("reviews", true);
    // eslint-disable-next-line
  }, []);

  const bv = useBV(product, {
    showReview: onShowReview,
  });

  function openPopupImage(imageUrl) {
    const inx = utils.array.findIndex(popupImages, (x) => x === imageUrl);
    setPopupIndex(inx >= 0 ? inx : 0);
    setToggler(!toggler);
  }

  function showTab(tab, forceToScroll = false) {
    if (tab === "wheretobuy" && !utils.site.isNZ) {
      console.log("__wheretobuy");
      bv.sendEvent(
        "WhereToBuy",
        "WhereToBuyTab_ProductPage",
        product.productCode
      );
    }
    setCurrentTab(tab);
    scrollTo("tab-" + tab, forceToScroll);
  }

  function scrollTo(selector, forceToScroll = false) {
    if (!forceToScroll && !isMobile) return;
    setTimeout(() => {
      const anchor = utils.ui.findElement(selector);
      if (anchor) utils.ui.scrollTo(anchor, 300);
    }, 200);
  }

  const slides = allPhotos.map((photo, index) =>
    photo.youtubeCheck ? (
      <div
        key={index}
        className={`productView__image ${
          heroImage.imageUrl === photo.imageUrl ? "current" : ""
        }`}
        onClick={() => setHeroImage(photo)}
      >
        <div
          className={`productView__imageBg youtube`}
          style={{
            backgroundImage: utils.css.bgUrlStyle(
              utils.site.resourcePath(photo.imageUrl)
            ),
          }}
        ></div>
        <div className='productView__imageBg__youtube__circle'>
          <FaRegPlayCircle />
        </div>
      </div>
    ) : (
      <div
        key={index}
        className={`productView__image ${
          heroImage.imageUrl === photo.imageUrl ? "current" : ""
        }`}
      >
        <div
          className={`productView__imageBg`}
          style={{
            backgroundImage: utils.css.bgUrlStyle(
              utils.site.resourcePath(photo.imageUrl)
            ),
          }}
          onClick={() => setHeroImage(photo)}
        ></div>
        {/* <img src={utils.site.resourcePath(photo.imageUrl)} alt={photo.alt} onClick={() => setHeroImage(photo)} /> */}
      </div>
    )
  );

  var settings = {
    dots: false,
    infinite: slides.length > 2 ? true : false,
    speed: 500,
    autoplaySpeed: 3000,
    initialSlide: 1,
    //fade: true,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const displayTabs = [
  //   true,
  //   product.seeMoreSection && product.seeMoreSection.length > 0,
  //   product.faqSection && product.faqSection.length > 0,
  //   true,
  //   product.whereToBuys && product.whereToBuys.length > 0,
  // ];

  // const tabs = [
  //   <div id='tab-details' className={`tab ${currentTab === 'details' && 'selected'}`} onClick={(e) => showTab('details')}>
  //     Details
  //   </div>,
  //   <div id='tab-seemore' className={`tab ${currentTab === 'seemore' && 'selected'}`} onClick={(e) => showTab('seemore')}>
  //     {' '}
  //     Recipes{' '}
  //   </div>,
  //   <div id='tab-faqs' className={`tab ${currentTab === 'faqs' && 'selected'}`} onClick={(e) => showTab('faqs')}>
  //     FAQs
  //   </div>,
  //   <div id='tab-reviews' className={`tab ${currentTab === 'reviews' && 'selected'}`} onClick={(e) => showTab('reviews')}>
  //     Reviews
  //   </div>,
  //   <div id='tab-wheretobuy' className={`tab ${currentTab === 'wheretobuy' && 'selected'}`} onClick={(e) => showTab('wheretobuy')}>
  //     Where to buy
  //   </div>,
  // ];

  // const tabContents = [
  //   <div className={`tabContent ${currentTab === 'details' && 'selected'}`}>
  //     <DetailsTab product={product} />
  //   </div>,
  //   <div className={`tabContent ${currentTab === 'seemore' && 'selected'}`}>
  //     <SeeMoreTab product={product} />
  //   </div>,
  //   <div className={`tabContent ${currentTab === 'faqs' && 'selected'}`}>
  //     <FaqTab product={product} />
  //   </div>,
  //   <div className={`tabContent ${currentTab === 'reviews' && 'selected'}`}>
  //     <div data-bv-show='reviews' data-bv-product-id={product.productCode}></div>
  //   </div>,
  //   <div className={`tabContent ${currentTab === 'wheretobuy' && 'selected'}`}>
  //     {product.whereToBuys && product.whereToBuys.length > 0 ? <Stores stores={product.whereToBuys} /> : <div>No Store</div>}
  //   </div>,
  // ];

  const displayTabs = [
    product.whereToBuys && product.whereToBuys.length > 0,
    true,
    true,
    product.seeMoreSection && product.seeMoreSection.length > 0,
    product.faqSection && product.faqSection.length > 0,
  ];

  const tabs = [
    <div
      id='tab-wheretobuy'
      className={`tab ${currentTab === "wheretobuy" && "selected"}`}
      onClick={(e) => showTab("wheretobuy")}
    >
      Where to buy
    </div>,

    <div
      id='tab-reviews'
      className={`tab ${currentTab === "reviews" && "selected"}`}
      onClick={(e) => showTab("reviews")}
    >
      Reviews
    </div>,

    <div
      id='tab-details'
      className={`tab ${currentTab === "details" && "selected"}`}
      onClick={(e) => showTab("details")}
    >
      Details
    </div>,

    <div
      id='tab-seemore'
      className={`tab ${currentTab === "seemore" && "selected"}`}
      onClick={(e) => showTab("seemore")}
    >
      {" "}
      Recipes{" "}
    </div>,
    <div
      id='tab-faqs'
      className={`tab ${currentTab === "faqs" && "selected"}`}
      onClick={(e) => showTab("faqs")}
    >
      FAQs
    </div>,
  ];

  const tabContents = [
    <div className={`tabContent ${currentTab === "wheretobuy" && "selected"}`}>
      {product.whereToBuys && product.whereToBuys.length > 0 ? (
        <Stores stores={product.whereToBuys} />
      ) : (
        <div>No Store</div>
      )}
    </div>,

    <div className={`tabContent ${currentTab === "reviews" && "selected"}`}>
      <div
        data-bv-show='reviews'
        data-bv-product-id={product.productCode}
      ></div>
    </div>,

    <div className={`tabContent ${currentTab === "details" && "selected"}`}>
      <DetailsTab product={product} />
    </div>,

    <div className={`tabContent ${currentTab === "seemore" && "selected"}`}>
      <SeeMoreTab product={product} />
    </div>,

    <div className={`tabContent ${currentTab === "faqs" && "selected"}`}>
      <FaqTab product={product} />
    </div>,
  ];

  const tabPanels = isMobile ? (
    <div className='productView_tabPanels--mobile'>
      {displayTabs[0] && (
        <div className='productView_section'>
          {tabs[0]} {tabContents[0]}
        </div>
      )}
      {displayTabs[1] && (
        <div className='productView_section'>
          {tabs[1]} {tabContents[1]}
        </div>
      )}
      {displayTabs[2] && (
        <div className='productView_section'>
          {tabs[2]} {tabContents[2]}
        </div>
      )}
      {displayTabs[3] && (
        <div className='productView_section'>
          {tabs[3]} {tabContents[3]}
        </div>
      )}
      {displayTabs[4] && (
        <div className='productView_section'>
          {tabs[4]} {tabContents[4]}
        </div>
      )}
    </div>
  ) : (
    <div className='productView_tabPanels--desktop'>
      <div className='productView__tabs'>
        {displayTabs[0] && tabs[0]}
        {displayTabs[1] && tabs[1]}
        {displayTabs[2] && tabs[2]}
        {displayTabs[3] && tabs[3]}
        {displayTabs[4] && tabs[4]}
      </div>

      <div className='productView__tabContents'>
        {displayTabs[0] && tabContents[0]}
        {displayTabs[1] && tabContents[1]}
        {displayTabs[2] && tabContents[2]}
        {displayTabs[3] && tabContents[3]}
        {displayTabs[4] && tabContents[4]}
      </div>
    </div>
  );

  const discoverMoreLinks = product.discoverMoreSection.map((x) =>
    JSON.parse(x.itemValue)
  );
  const barcodeCleaned = product.barcode
    ? product.barcode.replace(/-/g, "").replace(/ /g, "")
    : null;

  return (
    <div className='contentPanel productViewPanel'>
      <FsLightbox
        toggler={toggler}
        sources={popupImages}
        sourceIndex={popupIndex}
      />
      <MetaTag data={getMetaTag(product, mainPhoto)} />

      <div className='contentPanel__bg'></div>
      <div className='container'>
        <Breadcrumb breadcrumb={product.breadcrumb} />
        <div className='productView'>
          <div itemScope={true} itemType='http://schema.org/Product'>
            <meta itemProp='name' content={product.productName} />
            <div itemProp='brand' itemType='http://schema.org/Brand' itemScope>
              <meta itemProp='name' content={env.siteName} />
            </div>

            {product.intro && (
              <meta itemProp='description' content={product.intro} />
            )}
            {barcodeCleaned && <meta itemProp='sku' content={barcodeCleaned} />}
            {barcodeCleaned && (
              <meta itemProp='gtin13' content={barcodeCleaned} />
            )}

            {heroImage && heroImage.imageUrl && (
              <link
                itemProp='image'
                href={utils.site.fullUrl(heroImage.imageUrl)}
              />
            )}

            <div itemProp='offers' itemType='http://schema.org/Offer' itemScope>
              <link
                itemProp='url'
                href={utils.site.resourcePath(product.productUrl)}
              />
              {product.price && (
                <meta itemProp='price' content={product.price} />
              )}
              <meta
                itemProp='priceCurrency'
                content={utils.site.isNZ ? "NZD" : "AUD"}
              />
            </div>

            <div className='row'>
              <div className='col-md-6'>
                {/* <ImagePopup
              src={utils.site.resourcePath(heroImage.imageUrl)}
              thumbnailSrc={utils.site.resourcePath(heroImage.imageUrl)}
              alt={heroImage.alt} title={heroImage.alt} /> */}

                {heroImage.youtubeCheck && heroImage.youtubeCheck === true ? (
                  <div className='productView__hero productView__hero__youtube'>
                    <img src='/assets/white_bg.png' alt='white bg' />
                    <div className='productView__hero__youtube__item'>
                      {heroImage.youtubeUrl &&
                      heroImage.youtubeUrl.toLowerCase().includes(".m4v") ? (
                        <video
                          key={heroImage.youtubeUrl}
                          autoPlay='autoplay'
                          loop
                          muted
                          playsInline
                          style={{ width: "100%" }}
                        >
                          <source src={heroImage.youtubeUrl} type='video/mp4' />
                        </video>
                      ) : (
                        <YoutubeEmbed url={heroImage.youtubeUrl} />
                      )}
                    </div>
                    <div className='productView__hero__youtube__dumy'></div>
                  </div>
                ) : (
                  // <div className="productView__hero">
                  //   <YoutubeEmbed url={heroImage.video} />
                  // </div> :
                  <img
                    className='productView__hero'
                    src={utils.site.resourcePath(heroImage.imageUrl)}
                    alt={heroImage.alt || product.productName}
                    onClick={() => openPopupImage(heroImage.imageUrl)}
                  />
                )}

                {allPhotos.length > 1 && (
                  <div className='productView__images'>
                    <Slider {...settings}>
                      {slides}
                      {/* {
                        youtubeSection.map((item, index) =>
                        <div key={index} className={`productView__image ${heroImage.imageUrl === item.imageUrl ? 'current' : ''}`} onClick={() => setHeroImage(item)}>
                          <div className={`productView__imageBg youtube`}
                            style={{ backgroundImage: utils.css.bgUrlStyle(utils.site.resourcePath(item.imageUrl)) }}>
                          </div>
                          <div className="productView__imageBg__youtube__circle">
                            <FaRegPlayCircle />
                          </div>
                        </div>
                        )
                      } */}
                    </Slider>
                  </div>
                )}
              </div>
              <div className='col-md-6 right'>
                {product.productName.indexOf("i-") === 0 ? (
                  <h1 className='productView__title'>
                    <strong>
                      {product.productName.toUpperCase().replace("I-", "i-")}
                    </strong>
                  </h1>
                ) : (
                  <h1
                    className='productView__title'
                    style={{ textTransform: "uppercase" }}
                  >
                    <strong>{product.productName}</strong>
                  </h1>
                )}
                <div className='productView__price'>
                  {product.priceText ? product.priceText : ""}
                </div>
                <div className='productView__productCode'>
                  Product code: <b>{product.productCode}</b>
                </div>
                <div className='productView__bzSummary'>
                  <div
                    data-bv-show='rating_summary'
                    data-bv-product-id={product.productCode}
                  ></div>
                </div>
                {product.productStatus === 98 /*Discontinued*/ && (
                  <div className='productView__discontinued'>
                    This product is now discontinued.
                  </div>
                )}
                <div className='productView__intro'>{product.intro}</div>
                {discoverMoreLinks && (
                  <div className='productView__discoverMores'>
                    {discoverMoreLinks.map((link, index) => (
                      <div key={index} style={{ marginBottom: "0.8rem" }}>
                        <SiteLink
                          className='productView__skewButton'
                          to={link.url}
                        >
                          <span>{link.title}</span>
                        </SiteLink>
                      </div>
                    ))}
                  </div>
                )}

                <div className='productView__packages__container'>
                  <div className='productView__packages'>
                    {packagePhotos.map((photo, index) => (
                      <div key={index} className='productView__package'>
                        <img
                          src={utils.site.resourcePath(photo.imageUrl)}
                          //src="https://cuisinart.com.au/sites/cuisinart/media/products/rhb-100xa/20_rhb100xa_3d.png"
                          alt={photo.alt || product.productName + " Packages"}
                          onClick={() => openPopupImage(photo.imageUrl)}
                        />
                      </div>
                    ))}
                  </div>

                  {product.recipeBookletLink && (
                    <div className='productView__recipe'>
                      <a
                        href={product.recipeBookletLink}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <img
                          src='/assets/23_RecipeBook_Icon4.png'
                          alt='Recipe Book Icon'
                        />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {tabPanels}

            <RelatedProducts products={product.relatedProducts} />
          </div>
        </div>
      </div>
    </div>
  );
}

function getMetaTag(product, mainPhoto) {
  const country = utils.site.isNZ ? "New Zealand" : "Australia";
  const siteName = env.siteName + " " + country;
  return {
    title: product.productName + " | " + siteName,
    description: product.metaDesc || product.intro,
    keywords: "",
    heroImageUrl:
      product.metaImage && product.metaImage !== ""
        ? utils.site.resourcePath(product.metaImage)
        : utils.site.resourcePath(mainPhoto.imageUrl),
    language: "English",
    country: country,
    siteName: siteName,
    url: utils.site.resourcePath(product.productUrl),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  };
}

function RelatedProducts({ products }) {
  //console.log(products)
  if (products.length === 0) return null;
  return (
    <div className='productView__relatedProducts'>
      <h2>Other products you might like</h2>
      <ProductTiles products={products} />
    </div>
  );
}

function Stores({ stores }) {
  return (
    <ul className='stores'>
      {stores.map((store) => (
        <li key={store.storeCode} className='store'>
          <figure>
            <img
              src={`/assets/shops/${store.iconPath}`}
              alt={store.storeName}
            />
            <figcaption>{store.storeName}</figcaption>
            {store.buyOnline && (
              <a
                href={store.storeUrl}
                target='_blank'
                rel='noopener noreferrer'
              >
                Buy online <span className='icon'></span>
              </a>
            )}
          </figure>
        </li>
      ))}
    </ul>
  );
}

// function HowtoTab({ product }) {
//   const section = product.howToSection || [];
//   return <ItemList product={product} section={section} />;
// }

function FaqTab({ product }) {
  const section = product.faqSection || [];
  return <ItemList product={product} section={section} />;
}

function DetailsTab({ product }) {
  const section = product.detailSection || [];
  const hasWarranty = section.some(
    (x) => (x.itemValue || "").indexOf("WARRANTY") >= 0
  );

  const techSection = product.specSection || [];

  return (
    <React.Fragment>
      <ItemList product={product} section={section} />
      {techSection.length > 0 && (
        <div>
          <h3>TECH SPECS</h3>
          <ItemList product={product} section={techSection} />
        </div>
      )}

      {product.useSparePartInstruction && (
        <div>
          <h3>SPARE PARTS</h3>
          <p>
            Spare Parts are available -{" "}
            <SiteLink
              to={`/contact?modelNumber=${encodeURIComponent(
                product.productCode
              )}`}
            >
              Contact us
            </SiteLink>{" "}
            for more information
          </p>
        </div>
      )}
      {!hasWarranty && product.warranty && (
        <div>
          <h3>WARRANTY</h3>
          <p>
            {utils.text.isNumeric(product.warranty)
              ? `Backed by a ${product.warranty} year warranty`
              : product.warranty.toLowerCase().indexOf("backed") >= 0
              ? product.warranty
              : `Backed by a ${product.warranty.toLowerCase()} warranty`}
          </p>
        </div>
      )}
      {product.bookletLink && <Booklet bookletLink={product.bookletLink} />}
      {product.recipeBookletLink && (
        <RecipeBooklet recipeBookletLink={product.recipeBookletLink} />
      )}
      {product.barcode && (
        <div>
          <h3>BARCODE</h3>
          <p>{product.barcode}</p>
        </div>
      )}
    </React.Fragment>
  );
}

// function TechSpecTab({ product }) {
//   const section = product.specSection || [];
//   return (
//     <React.Fragment>
//       <ItemList product={product} section={section} />
//       {(product.barcode) && <div><h3>BARCODE</h3><p>{product.barcode}</p></div>}

//     </React.Fragment>
//   );
// }

function Booklet({ bookletLink }) {
  //const bookletTitle = utils.url.getFilename(bookletLink);
  return (
    <div>
      {/* <h3>INSTRUCTION BOOKLET</h3> */}
      <a href={bookletLink} target='_blank' rel='noopener noreferrer'>
        <FaRegFilePdf /> Download Instruction Booklet
      </a>
    </div>
  );
}

function RecipeBooklet({ recipeBookletLink }) {
  //const bookletTitle = utils.url.getFilename(recipeBookletLink);
  return (
    <div>
      {/* <h3>INSTRUCTION BOOKLET</h3> */}
      <a href={recipeBookletLink} target='_blank' rel='noopener noreferrer'>
        <FaRegFilePdf /> Download Recipe Booklet
      </a>
    </div>
  );
}
// function TechSpecs(items) {
//   console.log(items)
//   const specs = items.filter(x => x.itemTypeCode === "spec").map(x => JSON.parse(x.itemValue));
//   console.log(specs)
//   const left = specs.filter(x => x.category !== 'R')
//   const right = specs.filter(x => x.category === 'R')
//   if (right.length === 0) {
//     // render all - men
//     const splitLefts = [], splitRights = []
//     left.forEach((x, index) => index % 2 === 0? splitLefts.push(x) : splitRights.push(x) )
//     return (
//       <div className="row" style={{marginBottom: '0.5rem'}}>
//         <div className="col-md-6">
//         {splitLefts.map((x, index) => (<div key={index}><b>{x.label}</b>: {x.text}</div>))}
//         </div>
//         <div className="col-md-6">
//         {splitRights.map((x, index) => (<div key={index}><b>{x.label}</b>: {x.text}</div>))}
//         </div>
//       </div>
//     )
//   } else {
//     // render left/right - women
//     return (
//       <div className="row" style={{marginBottom: '0.5rem'}}>
//         <div className="col-md-6">
//           {left.map((x, index) => (<div key={index}><b>{x.label}</b>: {x.text}</div>))}
//         </div>
//         <div className="col-md-6">
//           {right.map((x, index) => (<div key={index}><b>{x.label}</b>: {x.text}</div>))}
//         </div>
//       </div>
//     )
//   }
// }

function SeeMoreTab({ product }) {
  // const section = product.seeMoreSection || [];
  // const blogs = [],
  //   instagrams = [];
  // const pre = [],
  //   post = [];

  // let hasInstaOrBlog = false;
  // section.forEach((item) => {
  //   if (item.itemTypeCode === "link") {
  //     hasInstaOrBlog = true;
  //     const link = JSON.parse(item.itemValue);
  //     if (link.url && link.url.indexOf("instagram.com") >= 0)
  //       instagrams.push(item);
  //     else if (link.linkType === "blog" || link.linkType === "page")
  //       blogs.push(item);
  //   } else if (hasInstaOrBlog) {
  //     post.push(item);
  //   } else {
  //     pre.push(item);
  //   }
  // });
  // return (
  //   <React.Fragment>
  //     <div>
  //       <ItemList product={product} section={pre} />
  //     </div>
  //     <div className='blogTiles tiles blogTiles--productView recipesTiles'>
  //       <ItemList product={product} section={blogs} />
  //     </div>
  //     <div css={style.instagrams}>
  //       <ItemList product={product} section={instagrams} />
  //     </div>
  //     <div>
  //       <ItemList product={product} section={post} />
  //     </div>
  //   </React.Fragment>
  // );
  const section = product.seeMoreSection || [];
  const blogs = [],
    instagrams = [];
  const pre = [],
    post = [],
    tiktoks = [];
  const empty = [0, 1, 2, 3, 4];

  let hasInstaOrBlog = false;
  section.forEach((item) => {
    let processed = false;
    if (item.itemTypeCode === "link") {
      const link = JSON.parse(item.itemValue);
      if (link.url && link.url.indexOf("instagram.com") >= 0) {
        hasInstaOrBlog = true;
        processed = true;
        instagrams.push(item);
      } else if (
        link.linkType === "blog" ||
        link.linkType === "page" ||
        link.linkType === "web"
      ) {
        hasInstaOrBlog = true;
        processed = true;
        if (link.title !== "") {
          blogs.push(item);
        }
      } else if (link.linkType === "tiktok") {
        hasInstaOrBlog = true;
        processed = true;
        tiktoks.push(item);
      }
    }
    if (!processed) {
      if (hasInstaOrBlog) {
        post.push(item);
      } else {
        pre.push(item);
      }
    }
  });
  return (
    <React.Fragment>
      <div>
        <ItemList product={product} section={pre} />
      </div>
      <div className='blogTiles tiles blogTiles--productView recipesTiles'>
        <ItemList product={product} section={blogs} />
      </div>
      <div css={style.instagrams}>
        <ItemList product={product} section={instagrams} />
        {empty.map((x) => (
          <Empty key={x} />
        ))}
      </div>
      <div css={style.tiktoks}>
        <ItemList product={product} section={tiktoks} />
        {empty.map((x) => (
          <Empty key={x} />
        ))}
      </div>
      <div>
        <ItemList product={product} section={post} />
      </div>
    </React.Fragment>
  );
}

// function ReviewTab({ product }) {
//   return <div>Coming...</div>
// }

function ItemList({ section }) {
  //console.log(section)
  return (
    <React.Fragment>
      {section.map((item, index) => (
        <ItemView item={item} key={index} />
      ))}
    </React.Fragment>
  );
}

function ItemView({ item }) {
  //console.log(item, item.itemTypeCode, item.itemTypeCode === 'html')
  if (item.itemTypeCode === "html") {
    return <div dangerouslySetInnerHTML={{ __html: item.itemValue }}></div>;
  }
  if (item.itemTypeCode === "video") {
    const video = JSON.parse(item.itemValue);

    if (video.video) {
      return (
        <div className='row video'>
          <div className='col-md-5'>
            <YoutubeEmbed url={video.video} title={video.title} />
          </div>
          <div className='col-md-7'>
            <h4>{video.title}</h4>
            <div dangerouslySetInnerHTML={{ __html: video.desc }}></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='row video'>
          <div className='col-12'>
            <h3>{video.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: video.desc }}></div>
          </div>
        </div>
      );
    }
  }
  if (item.itemTypeCode === "link") {
    // let link = JSON.parse(item.itemValue);
    // let title = link.title;
    // if (!link.title) {
    //   title = (link.url || "").split("/").pop();
    // }
    // if (link.url && link.url.indexOf("instagram.com") >= 0) {
    //   return (
    //     <div className='productView__linkItem productView__linkItem--instagram'>
    //       <InstagramEmbed
    //         url={link.url}
    //         maxWidth={320}
    //         hideCaption={true}
    //         clientAccessToken={env.instagramClientAccessToken}
    //       />
    //     </div>
    //   );
    // }
    // if (link.linkType === "blog" || link.linkType === "page") {
    //   return <PageTile page={link} />;
    // }
    // if (link.url && link.url.indexOf("http") < 0) {
    //   return (
    //     <div>
    //       <SiteLink
    //         className='productView__linkItem productView__linkItem--page'
    //         to={link.url}
    //       >
    //         {title}
    //       </SiteLink>
    //     </div>
    //   );
    // }
    // return (
    //   <div>
    //     <a
    //       className='productView__linkItem'
    //       href={link.url}
    //       target='_blank'
    //       rel='noopener noreferrer'
    //     >
    //       {title}
    //     </a>
    //   </div>
    // );
    let link = JSON.parse(item.itemValue);
    let title = link.title;
    if (!link.title) {
      title = (link.url || "").split("/").pop();
    }
    if (
      link.linkType === "instagram" ||
      (link.url && link.url.indexOf("instagram.com") >= 0)
    ) {
      return (
        <div className='productView__linkItem productView__linkItem--instagram'>
          {env.instagramUseFirst === "embed" && link.instagramEmbed ? (
            <div
              dangerouslySetInnerHTML={{ __html: link.instagramEmbed }}
            ></div>
          ) : link.url ? (
            <InstaEmbedByLink url={link.url} />
          ) : null}
        </div>
      );
    }
    if (link.linkType === "tiktok") {
      return (
        <div className='productView__linkItem productView__linkItem--tiktok'>
          <TiktokEmbedByLink url={link.url} />
        </div>
      );
    }
    if (link.linkType === "blog" || link.linkType === "page") {
      return <PageTile page={link} />;
    }
    if (link.url && link.url.indexOf("http") < 0) {
      return (
        <div>
          <SiteLink
            className='productView__linkItem productView__linkItem--page'
            to={link.url}
          >
            {title}
          </SiteLink>
        </div>
      );
    }
    if (link.url && link.url.indexOf("http") >= 0 && link.imageUrl) {
      return (
        <PageTile
          page={{
            imageUrl: link.imageUrl,
            url: link.url,
            title: title,
            tileDesc: "", //link.desc
          }}
        />
      );
    }
    return (
      <div>
        <a
          className='productView__linkItem'
          href={link.url}
          target='_blank'
          rel='noopener noreferrer'
        >
          <IoMdOpen /> {title}
        </a>
      </div>
    );
  }
  if (item.itemTypeCode === "qa") {
    const qa = JSON.parse(item.itemValue);
    return (
      <div css={style.qa}>
        <div css={style.qaQuestion}>{qa.question}</div>
        <div
          css={style.qaAnswer}
          dangerouslySetInnerHTML={{ __html: qa.answer }}
        ></div>
      </div>
    );
  }
  if (item.itemTypeCode === "spec") {
    const spec = JSON.parse(item.itemValue);

    return (
      <div>
        <strong>{spec.label}</strong>: {spec.text}
      </div>
    );
  }
  return <div></div>;
}

function Empty() {
  return (
    <div className='productView__empty'>
      <div></div>
    </div>
  );
}

function PageTile({ page }) {
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  return (
    <div className='blogTile'>
      <SiteLink className='blogTile__link' to={page.url}>
        <div
          className='blogTile__bg'
          style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
        ></div>
        <div className='blogTile__content'>
          <div className='blogTile__contentInner'>
            <div className='blogTile__title'>{page.title}</div>
            <div className='blogTile__desc'>{page.tileDesc}</div>
            <div className='blogTile__readMore thinBtn'> VIEW RECIPE </div>
          </div>
        </div>
      </SiteLink>
    </div>
  );
}
